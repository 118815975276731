// Table of Contents

.toc-wrapper {
  display: inline-block;
  position: sticky;
  top: calc(var(--spacer-3) * 2.5);
  width: auto;
  padding-left: var(--spacer);
  padding-right: var(--spacer);
}

.toc-title {
  text-align: center;
  font-size: 1em;
  font-weight: bold;
  border: 1px solid;
  border-radius: 0.3em 0.3em 0 0;
  padding: 0.5rem;
  margin: 0;
  color: var(--body-bg);
  background-color: var(--body-color);
  border-color: var(--border-color);
}

.toc-nav {
  font-size: 0.8em;
  max-height: 60vh;
  overflow-y: scroll;
  border: 1px solid;
  border-top: 0px;
  border-radius: 0 0 0.3em 0.3em;
  border-color: var(--border-color);

  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;

  ul {
    margin: 0;
    padding: 0;
  }

  a {
    padding: 0;
    margin: 0;
    display: block;
    text-decoration: none;
  }

  ul {
    list-style: none;

    li {
      font-weight: bold;

      a {
        padding-top: .2em;
        padding-bottom: .2em;
        padding-left: .5em;
        padding-right: .5em;
        border-bottom: 1px solid var(--border-color);
      }
    }
  }

  li ul li a {
    font-weight: normal;
    padding-left: 1.25em;
    padding-right: .5em;
  }
}
